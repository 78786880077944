import { ActionProps } from '~/components/Actions/props';
import IconAlarmClock from '~/icons/AlarmClock';
import IconClipboardList from '~/icons/ClipboardList';
import IconStopWatch from '~/icons/StopWatch';
import IconTasks from '~/icons/Tasks';

const useTasks = ({
  pathname,
}: {
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/admin/tasks${to}` === pathname ? 'is-active' : undefined;
    }

    return pathname.slice(0, `/admin/tasks${to}`.length) === `/admin/tasks${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconTasks,
      label: 'Tâches',
      to: `/admin/tasks`,
    },
    {
      className: isActive(`/checklists`),
      icon: IconClipboardList,
      label: 'Listes de contrôle',
      to: `/admin/tasks/checklists`,
    },
    {
      className: isActive(`/reminders`),
      icon: IconAlarmClock,
      label: 'Rappels',
      to: `/admin/tasks/reminders`,
    },
    {
      className: isActive(`/tracking`),
      icon: IconStopWatch,
      label: 'Suivi',
      to: `/admin/tasks/tracking`,
    },
  ];
};

export default useTasks;
