import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconFlag: FC<IconType> = ({ className, color, onClick, size = '16' }) => (
  <svg
    className={className}
    clipRule="evenodd"
    fillRule="evenodd"
    height={size}
    onClick={onClick}
    strokeLinejoin="round"
    strokeMiterlimit={2}
    version="1.1"
    viewBox="0 0 16 16"
    width={size}
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(0.034966,0,0,0.034966,-1.04027,-0.876647)">
      <path
        d="M349.5,149.652C384.383,149.652 460.083,122.137 464,120.5L464,330.5C461.826,331.894 459.687,333.378 457.578,334.926C431.134,349.555 411.737,356.97 375.7,356.97C308.3,356.97 264.1,322.17 210.5,322.17C159.7,322.17 124.4,332.17 96.1,344.27C96.1,344.27 95.688,261.358 96,205.1L96,135.204C96.165,135.132 96.338,135.055 96.519,134.973C102.219,132.385 108.79,129.609 116.3,126.852C137,119.252 159.3,114.852 184.3,114.852C251.7,114.852 296,149.652 349.5,149.652Z"
        fill={color || 'currentColor'}
      />
    </g>
    <g transform="matrix(0.03125,0,0,0.03125,0,0)">
      <path
        d="M466.5,66.9C432.2,82.8 390,98.8 349.5,98.8C296,98.8 251.7,64 184.3,64C159.3,64 137,68.4 116.3,76C119.1,68.7 120.4,60.8 119.9,52.4C118.1,24 94.8,1.2 66.3,0C34.3,-1.3 8,24.3 8,56C8,75 17.5,91.8 32,101.9L32,488C32,501.3 42.7,512 56,512L72,512C85.3,512 96,501.3 96,488L96,393.6C124.3,381.5 159.6,371.5 210.4,371.5C264,371.5 308.2,406.3 375.6,406.3C423.8,406.3 462.3,390 498.1,365.4C506.8,359.4 511.9,349.6 511.9,339L511.9,95.9C512,72.6 487.7,57.1 466.5,66.9ZM349.5,149.652C384.383,149.652 460.083,122.137 464,120.5L464,330.5C461.826,331.894 459.687,333.378 457.578,334.926C431.134,349.555 411.737,356.97 375.7,356.97C308.3,356.97 264.1,322.17 210.5,322.17C159.7,322.17 124.4,332.17 96.1,344.27C96.1,344.27 95.688,261.358 96,205.1L96,135.204C96.165,135.132 96.338,135.055 96.519,134.973C102.219,132.385 108.79,129.609 116.3,126.852C137,119.252 159.3,114.852 184.3,114.852C251.7,114.852 296,149.652 349.5,149.652Z"
        fill={color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconFlag;
