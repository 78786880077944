import { DocumentType, FeatureType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import { FeatureData, TaskData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Task';
import List from '~/containers/Espace/List';
import params from '~/params/task.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useAdminTasks from '~/utils/useAdminTasks';

const PageAdminTasks: FC<
  PageProps & UserProps & { pageContext: { tab?: number } }
> = ({ location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useAdminTasks({
    pathname: location.pathname,
  });

  const [features, setFeatures] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData({});

    const unsub = feature.watch(
      docs => {
        if (setFeatures) {
          setFeatures(docs);
        }
      },
      {
        orderDirection: 'asc',
        orderField: 'label',
        wheres: {
          hidden: false,
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, []);

  return (
    <TemplateAdmin>
      <List
        filters={compact([
          features &&
            features.length > 0 && {
              label: 'Caractéristique',
              multiple: true,
              name: 'search.features',
              options: features.map(feature => ({
                label: feature.label ?? feature.id,
                value: feature.id,
              })),
              type: 'array',
            },
          {
            label: 'Priorité',
            name: 'priority',
            options: [
              {
                label: 'Urgente',
                value: 'urgent',
              },
              {
                label: 'Haute',
                value: 'high',
              },
              {
                label: 'Normal',
                value: 'normal',
              },
              {
                label: 'Basse',
                value: 'low',
              },
            ],
          },
        ])}
        itemList={Item}
        model={
          new TaskData({
            params,
            wheres: {
              // parent: '',
            },
          })
        }
        search={location.search}
        subMenu={items}
        tabIndex={tab ?? 0}
        title={t('tasks.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminTasks, 'admin');
